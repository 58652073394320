import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import Root from '../Root'
import Header from '../Header'
import Footer from '../Footer'

const Wrapper = styled.div``

const Layout = ({ children }) => (
  <Root>
    <Helmet>
      <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700" rel="stylesheet" />
    </Helmet>
    <Header />
    <Wrapper>{children}</Wrapper>
    <Footer />
  </Root>
)

export default Layout
