import React from 'react'

const Logo = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 282 79.71"
    className={className}
    style={{ display: 'block', color: '#000', stroke: 'none', fill: 'currentColor' }}
  >
    <g transform="translate(-7.8403152,-147.81619)">
      <g transform="matrix(2.0659909,0,0,2.0659909,-1.3041935,-215.91577)" id="g1031">
        <path d="m 48.692592,177.88217 c 5.934008,12.67741 8.42876,15.97139 11.041658,20.8873 -4.07196,-1.30042 -8.90844,-8.48475 -12.549036,-8.91756 -3.184116,-0.37855 -9.031079,10.11911 -17.304099,13.73691 7.771059,-7.52466 10.273768,-17.57575 15.988916,-25.81302 1.540562,-2.466 1.769859,-2.14345 2.822561,0.10637 z" />
        <path d="m 38.907814,192.83056 c -0.91278,0.92071 -3.6296,6.58929 -3.917319,5.70096 -0.565372,-1.74556 -2.99574,-4.54551 -4.018784,-4.58531 -3.746926,-0.14579 -5.037674,4.04352 -10.853028,9.95396 -6.063475,6.1626 -11.2569544,9.06914 -15.2084442,10.7372 8.7747672,-6.48164 16.8030952,-17.02063 25.0052092,-29.9506 2.287168,-3.6261 2.491813,-1.74505 4.605689,1.19969 2.79179,3.88913 3.368496,5.45748 4.386677,6.9441 z" />
      </g>
      <g transform="scale(0.96094037,1.0406473)">
        <path d="m 145.93491,173.40899 q 6.10284,0 10.14233,3.95231 4.0395,3.92326 4.0395,9.88079 0,5.78316 -4.09762,9.61923 -4.09762,3.80701 -10.3167,3.80701 -6.01565,0 -10.05515,-3.86513 -4.0395,-3.89419 -4.0395,-9.70642 0,-5.87035 4.06856,-9.76454 4.09762,-3.92325 10.25858,-3.92325 z m -0.29061,4.76603 q -3.80701,0 -6.24815,2.49925 -2.44113,2.49926 -2.44113,6.36439 0,3.83607 2.49926,6.27721 2.49925,2.41207 6.42251,2.41207 3.89419,0 6.36438,-2.44114 2.49926,-2.47019 2.49926,-6.30626 0,-3.80701 -2.58644,-6.30627 -2.58644,-2.49925 -6.50969,-2.49925 z" />
        <path d="m 172.96362,173.46711 v 3.40015 q 3.54546,-3.92325 8.04993,-3.92325 2.49926,0 4.64978,1.30775 2.15053,1.27869 3.25485,3.54546 1.13338,2.23771 1.13338,7.11998 v 15.28615 h -5.28912 v -15.22803 q 0,-4.09762 -1.24963,-5.84129 -1.24963,-1.77273 -4.1848,-1.77273 -3.74889,0 -6.36439,3.74889 v 19.09316 h -5.40537 v -26.73624 z" />
        <path d="m 195.31348,177.71004 9.93891,-9.76454 v 5.52161 h 8.45679 v 4.76603 h -8.45679 v 13.10657 q 0,4.59166 3.80701,4.59166 2.84799,0 6.01565,-1.91804 v 4.9404 q -3.05141,1.7146 -6.65499,1.7146 -3.63265,0 -6.04472,-2.12146 -0.75559,-0.63935 -1.24963,-1.424 -0.49404,-0.81371 -0.84277,-2.0924 -0.31967,-1.30775 -0.31967,-4.94039 v -11.85694 h -4.64979 z" />
        <path d="m 237.3668,184.13255 v 11.3629 q 0,1.36587 0.92996,1.36587 0.95901,0 2.99329,-1.42399 v 3.22578 q -1.80179,1.16245 -2.90611,1.56931 -1.07526,0.43591 -2.26677,0.43591 -3.40015,0 -4.01043,-2.67362 -3.3711,2.6155 -7.1781,2.6155 -2.78987,0 -4.64979,-1.83085 -1.85991,-1.85991 -1.85991,-4.64978 0,-2.52832 1.80179,-4.50448 1.83085,-2.00522 5.17288,-3.16766 l 6.77125,-2.32489 v -1.424 q 0,-4.82415 -4.82415,-4.82415 -4.33011,0 -8.42773,4.47542 v -5.78317 q 3.08048,-3.63264 8.86365,-3.63264 4.33011,0 6.94561,2.26677 0.87183,0.72653 1.5693,1.9471 0.69747,1.1915 0.87183,2.41207 0.20343,1.19151 0.20343,4.5626 z m -5.20194,10.78168 v -7.93369 l -3.54546,1.36587 q -2.70269,1.07526 -3.83607,2.17959 -1.10432,1.07526 -1.10432,2.70268 0,1.65649 1.0462,2.70269 1.07526,1.0462 2.76081,1.0462 2.52831,0 4.67884,-2.06334 z" />
        <path d="m 253.32322,173.46711 v 6.1319 l 0.29061,-0.46498 q 3.83607,-6.19002 7.67214,-6.19002 2.99329,0 6.24814,3.02236 l -2.78987,4.64978 q -2.76081,-2.6155 -5.11476,-2.6155 -2.55738,0 -4.44635,2.44113 -1.85991,2.44114 -1.85991,5.78317 v 13.9784 h -5.31819 v -26.73624 z" />
        <path d="m 285.67012,173.40899 q 6.10284,0 10.14234,3.95231 4.0395,3.92326 4.0395,9.88079 0,5.78316 -4.09762,9.61923 -4.09762,3.80701 -10.31671,3.80701 -6.01565,0 -10.05515,-3.86513 -4.0395,-3.89419 -4.0395,-9.70642 0,-5.87035 4.06856,-9.76454 4.09762,-3.92325 10.25858,-3.92325 z m -0.29061,4.76603 q -3.80701,0 -6.24814,2.49925 -2.44114,2.49926 -2.44114,6.36439 0,3.83607 2.49926,6.27721 2.49926,2.41207 6.42251,2.41207 3.89419,0 6.36439,-2.44114 2.49926,-2.47019 2.49926,-6.30626 0,-3.80701 -2.58644,-6.30627 -2.58645,-2.49925 -6.5097,-2.49925 z" />
      </g>
    </g>
  </svg>
)

export default Logo
