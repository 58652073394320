import { rem, tint, lighten } from 'polished'

const colors = {
  black: '#000000',
  white: '#FFFFFF',
  slate: '#023F53',
  orange: '#FF8300',
}

const contextualColors = {
  primary: colors.slate,
  lightPrimary: lighten(0.1, colors.slate),
  secondary: colors.orange,
  primaryText: tint(0.15, colors.black),
  dark: tint(0.15, colors.black),
  light: tint(0.95, colors.black),
}

const theme = {
  typography: {
    fontFamily: `'MontSerrat', sans-serif`,
    fontSize: rem(14),
  },
  breakpoints: {
    sm: '640px',
    md: '1024px',
    lg: '1280px',
  },
  colors: {
    ...colors,
    ...contextualColors,
  },
}

export default theme
