import React from 'react'
import styled from 'styled-components'
import { theme, withProp } from 'styled-tools'
import { rem, tint } from 'polished'
import { useStaticQuery, graphql } from 'gatsby'
import Icon from '../Icon'

const Wrapper = styled.footer`
  background: ${theme('colors.dark')};
  color: ${withProp('theme.colors.black', tint(0.75))};
`

const InnerWrapper = styled.div`
  max-width: ${rem(800)};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;
  text-align: center;
  padding: ${rem(28)} ${rem(21)} ${rem(36)};
`

const Address = styled.address`
  p {
    margin: ${rem(2)} 0;
    font-size: ${rem(11)};

    &:first-child {
      margin-bottom: ${rem(8)};
    }
  }
`

const Contact = styled.ul`
  display: flex;
  padding-left: 0;
  margin: 0;
  margin-bottom: ${rem(14)};
  list-style-type: none;

  li {
    padding: ${rem(14)};
  }
`

const StyledIcon = styled(Icon)`
  color: ${withProp('theme.colors.black', tint(0.75))};
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      footerYaml {
        address {
          email
        }
        contact {
          type
          href
        }
      }
    }
  `)
  const {
    footerYaml: {
      address: { email },
      contact,
    },
  } = data
  return (
    <Wrapper>
      <InnerWrapper>
        <Contact>
          {contact.map(item => (
            <li key={item.href}>
              <a href={item.href} target="_blank" rel="noopener noreferrer">
                <StyledIcon type={item.type} />
              </a>
            </li>
          ))}
        </Contact>
        <Address>
          <p>{email}</p>
        </Address>
      </InnerWrapper>
    </Wrapper>
  )
}

export default Footer
