import { createElement } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import Envelope from './Envelope'
import LinkedIn from './LinkedIn'
import MapMarker from './MapMarker'

const icons = {
  mail: Envelope,
  linkedIn: LinkedIn,
  location: MapMarker,
}

const Icon = ({ type, ...props }) => createElement(icons[type], props) || null

export default styled(Icon)`
  display: block;
  fill: currentColor;
  width: ${rem(28)};
  height: ${rem(28)};
`
