import { createGlobalStyle } from 'styled-components'
import { theme } from 'styled-tools'
import { normalize, rem } from 'polished'

export default createGlobalStyle`
  ${normalize()};

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  :root {
    font-family: ${theme('typography.fontFamily')};
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    color: ${theme('colors.primaryText')};
    overflow-x: hidden;
    @media (min-width: ${theme('breakpoints.md')}) {
      font-size: 18px;
    }
  }

  body {
    margin: 0;
    font-size: ${rem(16)};
    text-align: center;
    overflow-x: hidden;
  }

  h1 {
    font-size: ${rem(32)};
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.07em;
    line-height: 1;
    margin: 0;
  }

  h2 {
    font-size: ${rem(32)};
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 0.05em;
    line-height: 1;
    margin: 0;
  }

  h3 {
    font-size: ${rem(22)};
    font-weight: 500;
    line-height: 1;
    margin: 0;
    margin-bottom: ${rem(14)};
  }

  p {
    font-size: ${rem(16)};
    font-weight: 400;
    line-height: 1.4;
    margin: ${rem(7)} 0;
  }

  address {
    font-style: normal;
  }

  a {
    color: ${theme('colors.primary')};
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }

  button {
    border-radius: ${rem(4)};
    font-weight: 600;
    text-transform: uppercase;
    padding: 0.9em 1.6em;
    cursor: pointer;
  }

`
