import React /*, { useState } */ from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { media } from '../../theme'

import Logo from '../Logo'
// import Hamburger from '../Hamburger'

// const StyledHamburger = styled(Hamburger)`
//   position: absolute;
//   top: ${rem(24)};
//   right: ${rem(21)};
//   ${media('sm')`
//     top: ${rem(36)};
//     right: ${rem(36)};
//   `}
//   ${media('md')`
//     top: ${rem(44)};
//     right: ${rem(44)};
//   `}
// `

const StyledLogo = styled(Logo)`
  height: ${rem(40)};
  margin: ${rem(12)} auto;
  ${media('sm')`
    height: ${rem(50)};
    margin: ${rem(18)} auto;
  `}
  ${media('md')`
    height: ${rem(70)};
    margin: ${rem(18)} auto;
  `}
`

const Wrapper = styled.header`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
`

const Header = () => {
  // const [open, setOpen] = useState(false)
  return (
    <Wrapper>
      <StyledLogo />
      {/*<StyledHamburger open={open} onClick={() => setOpen(open => !open)} />*/}
    </Wrapper>
  )
}

export default Header
